import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class AlertApi extends BaseApi {
  getAlerts(start_date, end_date) {
    const localVarAxiosArgs = {
      url: `/alert/alerts`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getHandlers() {
    const localVarAxiosArgs = {
      url: `/alert/handlers`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  addHandler(data) {
    const localVarAxiosArgs = {
      url: `/alert/handler`,
      options: {
        method: 'POST',
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateEndpoint(data) {
    const localVarAxiosArgs = {
      url: `/alert/endpoint`,
      options: {
        method: 'POST',
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateDeliveryRateCriteria(data) {
    const localVarAxiosArgs = {
      url: `/alert/delivery_rate_criteria`,
      options: {
        method: 'POST',
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteHandler(handler_id) {
    const localVarAxiosArgs = {
      url: `/alert/handler`,
      options: {
        method: 'DELETE',
        params: {
          handler_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getAllEventTypes() {
    const localVarAxiosArgs = {
      url: `/alert/all_event_types`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getAllAlertTypes() {
    const localVarAxiosArgs = {
      url: `/alert/all_alert_types`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
