import dayjs from 'dayjs';

export function getDateNum(num) {
  if (num < 10) return `0${num}`;
  return num;
}

export function initialSearchDate() {
  let startDate = new Date();
  let endDate = new Date();
  startDate.setDate(startDate.getDate() - 6);

  return { startDate, endDate };
}

export function getInitialSearchDateRange(store) {
  const { startDate, endDate } = store.state.searchDateRange;

  return {
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  };
}

export function convertDateToString(date, formatType = 'YYYY-MM-DD') {
  if (typeof date.toISOString === 'function')
    return dayjs(date.toISOString()).format(formatType);
  return dayjs(date).format(formatType);
}

export const DETAILS_TYPE = {
  CREATE: 'create',
  EDIT: 'edit',
};

export const copyToClipboard = (text) => {
  var dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
};

export const getDatesBetweenDays = (startDate, endDate) => {
  const dates = [];
  let currDate = dayjs(startDate.toISOString()).startOf('day');
  const lastDate = dayjs(endDate.toISOString()).startOf('day');

  dates.push(currDate.clone());
  while (currDate.diff(lastDate) < 0) {
    currDate = currDate.add(1, 'days');
    dates.push(currDate.clone());
  }

  return dates;
};

export const getDeliveryScore = (data) => {
  const spamscore = data.spamscore
    ? parseFloat(data.spamscore).toFixed(1)
    : 0.0;
  const barracudascore = data.barracudascore
    ? parseFloat(data.barracudascore).toFixed(1)
    : 0.0;

  const proofpointscore = data.proofpointscore
    ? parseFloat(data.proofpointscore).toFixed(1)
    : 0.0;

  const result = { spamscore, barracudascore, proofpointscore };
  result.reportid = data.reportid;

  if (spamscore >= -10 && spamscore <= 1)
    result.spamscoreIcon = 'fa fa-check-circle green-circle';
  else if (spamscore > 1 && spamscore <= 4)
    result.spamscoreIcon = 'fa fa-minus-circle yellow-circle';
  else if (spamscore > 4)
    result.spamscoreIcon = 'fa fa-exclamation-circle red-circle';

  if (barracudascore >= -10 && barracudascore <= 3.9)
    result.barracudascoreIcon = 'fa fa-check-circle green-circle';
  else if (barracudascore > 3.9 && barracudascore <= 9)
    result.barracudascoreIcon = 'fa fa-minus-circle yellow-circle';
  else if (barracudascore >= 10)
    result.barracudascoreIcon = 'fa fa-exclamation-circle red-circle';

  if (proofpointscore > 0) {
    result.proofpointIcon = 'fa fa-check-circle green-circle';
    result.proofpointDescription = 'Passed Proofpoint SPAM Filter';
  } else {
    result.proofpointIcon = 'fa fa-exclamation-circle red-circle';
    result.proofpointDescription = 'Failed Proofpoint SPAM Filter';
  }

  return result;
};

export const getCustomerPreference = () => {
  const jsonStr = localStorage.getItem('customer_preference');
  if (jsonStr) return JSON.parse(jsonStr);
  return {};
};

export const getDeliveryInbox = (record) => {
  return record.expandedglobalinbox !== null
    ? parseFloat(record.expandedglobalinbox).toFixed(1)
    : 0;
};

export const getDeliverySpam = (record) => {
  return record.expandedglobalspam !== null
    ? parseFloat(record.expandedglobalspam).toFixed(1)
    : 0;
};

export const getDeliveryFail = (record) => {
  return (100 - getDeliveryInbox(record) - getDeliverySpam(record)).toFixed(1);
};

export const downloadCSV = (rows, filename) => {
  const encodedUri = encodeURI(
    `data:text/csv;charset=utf-8,${rows
      .map((item) => item.join(','))
      .join('\r\n')}`
  );
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const scrollToTopOfMain = () => {
  const mainElement = document.querySelector('.ant-layout-content');
  if (mainElement) {
    mainElement.scrollTop = 0;
  }
};

export const convertTimezoneByHourDifference = (
  datetimeString,
  hourDifference
) => {
  if (datetimeString === null || datetimeString === undefined) return '';
  const hourDifferenceUTC = hourDifference - 8;

  let datetime = new Date(datetimeString + 'Z');
  datetime.setHours(datetime.getHours() + hourDifferenceUTC);
  let formattedDate = datetime
    .toISOString()
    .replace('T', ' ')
    .replace(/\.\d+Z$/, '');

  // console.log(formattedDate);
  return formattedDate;
};

export const checkIPDomain = (value) => {
  const ipRegex =
    /^((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?))$/;
  const domainRegex = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

  if (ipRegex.test(value) || domainRegex.test(value)) {
    return true;
  } else {
    return false;
  }
};
